.Header {
  width: 100%;
  height: 74px;
  /* overflow: hidden; */
  display: grid;
  grid-template-columns: [icon] min-content [search] auto[language] 70px [menu] 75px;
  grid-template-rows: 74px;
  position: relative;

  .Language,.Menu {
    box-sizing: border-box;
    padding: 5px;
  }

  .Icon {
    height: 100%;
    /* position: absolute; */
    /* left: 0; */
    /* top: 0; */
    cursor: pointer;
    background-image: url("/OSMS_logga_utanmost.png");
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .logo {
    padding: 10px;
    box-sizing: border-box;
  }

  .smol {
    display: none;
    height: 100%;
    img {
      height: 100%;
    }
  }
  .wide {
    display: block;
    height: 100%;
    img {
      height: 100%;
    }
  }

  svg {
    height: 100%;
    path, polyline {
      fill: rgb(160, 168, 171)!important;
    }
  }

  .desktopSearch {
    display: block;
    height: 100%;
    padding: 10px 10px;
    box-sizing: border-box;

    .searchText {
      width: 100%;
      border: 0;
      height: 100%;
      font-size: 26px;
    }
    .searchText:focus{
      outline: none;
    }
    .searchText::-webkit-input-placeholder {
      color: #a0a8ab;
    }
  }
  .mobileSearch {
    display: none;

    height: 100%;

    padding: 10px 0;
    box-sizing: border-box;

    svg {
      height: 60px;
      width: 60px;
      vertical-align: middle;
    }
  }
  @media only screen
  and (max-width: 900px) {
    /* grid-template-columns: [icon] min-content [search] auto[language] 70px [menu] 55px; */
    .Icon {
      background-image: url("/OSMS_logga2.png");
    }
    .smol {
      display: block;
    }
    .wide {
      display: none;
    }

    .desktopSearch {
      display: none;
    }
    .mobileSearch {
      display: block;
    }
  }
}
.Header.languageEnabled:not(.menuEnabled) {
  grid-template-columns: [icon] min-content [search] auto [language] 70px;
}

