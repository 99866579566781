.Root {

  height: 100vh;
  display: grid;
  grid-template-rows: [header] 74px [content] auto [footer] 25px;
  grid-template-rows: [header] 74px [content] auto;
  overflow: hidden;

  font-family: 'Open Sans', sans-serif;

  .Content {
    display: grid;
    grid-template-columns: [map] 65% [list] auto;
    position: relative;

    .List {
      display: grid;
      grid-template-rows: [titlebar] 140px [list] auto;
      position: relative;
      height: 100%;
      /* overflow: hidden; */

      .ListSwapButton {
        display: none;

        background: #FA8228;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
      }
      @media only screen
      and (max-width: 900px) {
        .ListSwapButton {
          display: block;
        }
      }

      .Listcontent {
        overflow-x: hidden;
        overflow-y: auto;

        position: absolute;
        top: 140px;
        bottom: 0;
        width: 100%;
        background: #f2f1ef;

        .Tab {
          display: none;
          box-sizing: border-box;
          padding: 0 0 30px 0;

          .Info {
            padding: 20px 20px;
            box-sizing: border-box;
          }
        }
        .Tab.Active {
          display: block;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .Content {
      grid-template-columns: [list] 30px [map] auto;
    }
    .Content.Swapped {

      .List {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
      }

      .Map {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
      }
    }
  }

}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.leftList_menu .gridcontainer .griddyhtml, body, div {
  padding: 0;
  margin: 0;
  border: 0;
}
