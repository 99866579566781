.Map {
  width: 100%;
  height: 100%;
  position: relative;
  .leaflet-container {
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .MapSwapButton {
    display: none;

    background: #d9d9d9;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }
  @media only screen
  and (max-width: 900px) {
    .MapSwapButton {
      display: block;
    }
  }

  .leaflet-popup-content-wrapper {
    border-radius: 0;

    .leaflet-popup-content {
      width: auto !important;
      margin: 0;

      .wrap {
        width: auto !important;
        display: grid;
        /* grid-template-columns: 80px auto; */
        /* margin: 8px 20px 8px 10px; */

        img {
          /* width: 70px; */
        }
        .img {
          width: 128px;
          height: 72px;
          background-color: black;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        .title {
          padding: 2px;
        }
        div {
          /* word-break: break-word; */
          /* padding: 0 5px; */
        }
      }
    }
  }

  .leaflet-div-icon {
    background: transparent;
    border: 0;
  }
}
.Map.moveLeft {
  .leaflet-bottom.leaflet-right {
    right: 400px;
  }
}
